import { init } from '@jill64/sentry-sveltekit-cloudflare/client';

const onError = init(
	'https://02fe611d18b33689fe3c25239468b707@o4505972255883264.ingest.us.sentry.io/4505972256866304',
	{
		sentryOptions: {}
	}
);

export const handleError = onError((e, sentryEventId) => {
	// Your Error Handler
});
